<template>
    <div>
        <b-row>
            <b-col sm="6" v-for="(item, i) in defaultGateNotificationTypes" :key="i">
                <b-form-checkbox v-model="defaultGateNotificationTypes[i].value">
                    <b>{{ item.name }}</b>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr />

        <b-row align-v="center">
            <!--<b-col>
                <b-button variant="primary"><b-icon icon="plus" class="mr-2"></b-icon>REINVITE USER</b-button>
            </b-col>-->
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="cancel()" variant="primary" class="mr-1">CANCEL</b-button>
                <b-button @click="updateNotificationSettings()" variant="success">SAVE</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import notificationService from "@/services/notificationService";
import { NotificationType, DefaultGateNotificationTypes } from '@/variables/notificationVariables';

export default {
    props: {
        gateUser: {},
        gate: {},
    },
    data() {
        return {
            defaultGateNotificationTypes: DefaultGateNotificationTypes
        }
    },
    methods: {
        cancel() {
            this.$root.$emit('bv::hide::modal', 'notifications-modal');
        },
        async updateNotificationSettings() {
            await notificationService.setNotificationTypes(this.gateUser.id, this.defaultGateNotificationTypes.map(x => 
            {
                return {
                    type: x.type,
                    isEnabled: x.value
                }
            }))
            this.$root.$emit('dmx::saved::gateUserNotifications');
        },
        async loadNotifications() {
            let typeModels = [...(await notificationService.getNotificationTypes(this.gateUser.id))]
            this.defaultGateNotificationTypes.forEach(x => {
                typeModels.forEach(y => {
                    if (y.type === x.type) {
                        x.value = y.isEnabled;
                    }
                })
            })
        }
    },
    computed: {
        ...mapState({
            stateUser: store => store.authentication.user,
        })
    },
    watch: {

    },
    mounted() {
        this.loadNotifications()
    }
}
</script>