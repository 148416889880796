<template>
    <div>
        <b-row>
            <b-col sm="6" v-if="gate.userGatePermissions.length || gateUser.userGatePermissions !== undefined" v-for="(item, i) in defaultGateUserPermissions" :key="i">
                <b-form-checkbox v-model="defaultGateUserPermissions[i].value">
                    <b>{{ item.name }}</b>
                </b-form-checkbox>
                <span class="small">
                    <i>{{item.description}}</i>
                </span>
            </b-col>
            <b-col sm="6" v-if="!gate.userGatePermissions.length || gateUser.userGatePermissions === undefined">
                <h5>This gate needs a subscription!</h5>
            </b-col>
        </b-row>
        <div v-if="gateUser.gateOperationCode != 0">
            <hr />
            Current gate operation code: {{ gateUser.gateOperationCode }}
            <b-button size="sm" variant="warning" class="mt-2" @click="resetInAppAuthenticationCode()">RESET CURRENT APPLICATION CODE</b-button>
        </div>
        <hr />

        <b-row align-v="center">
            <!--<b-col>
                <b-button variant="primary"><b-icon icon="plus" class="mr-2"></b-icon>REINVITE USER</b-button>
            </b-col>-->
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="cancel()" variant="primary" class="mr-1">CANCEL</b-button>

                <b-button v-if="gate.userGatePermissions.length || gateUser.userGatePermissions !== undefined" @click="updateUserGatePermissions()" variant="success">SAVE</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateService from '@/services/gateService';
    import router from '@/router';
    import accountService from '@/services/accountService';
    import { UserRoles } from '@/variables/variables.js';
    import { DefaultGateUserPermissions } from '@/variables/permissionVariables';
    import { mapState, mapActions } from 'vuex';

    export default {
        props: {
            gateUser: {},
            gate: {},
        },
        data() {
            return {
                defaultGateUserPermissions: DefaultGateUserPermissions
            }
        },
        methods: {
            cancel() {
                this.$root.$emit('bv::hide::modal', 'permission-modal');
            },
            updateUserGatePermissions() {
                gateService.updateUserGatePermissions({
                    UserId: this.gateUser.userId || this.gateUser.id,
                    GateId: this.gate.id,
                    permissionsList: this.defaultGateUserPermissions
                }).then((data) => {
                    //console.log('updateUserGatePermissions data', data);
                    this.$root.$emit('dmx::saved::gateUserPermissions');
                });
            },
            resetInAppAuthenticationCode() {
                accountService.resetInAppAuthenticationCode(this.gateUser.id)
                    .then((data) => {
                        this.makeToast('Gate user permissions', `Authentication code has been reset`, 'warning');
                    });
            },
            ...mapActions({
                hasGatePermission: 'authentication/hasGatePermission',
            }),
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            })
        },
        watch: {
            
        },
        mounted() {
            if(this.gate.userGatePermissions.length || this.gateUser.userGatePermissions !== undefined){
                this.defaultGateUserPermissions.map((x) => {

                        let ugp = (this.gate.userGatePermissions.length) ? this.gate.userGatePermissions : this.gateUser.userGatePermissions
                ugp.map((y) => {
                    if (y == x.permission) x.value = true;
                });
                    
            });
        }
        }
    }
</script>